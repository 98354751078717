*{
  margin: 0;
  padding: 0;
  font-family: miligram;
  font-size: 18px;
}
body {
  width: 100%;
  height: auto;
  max-width: 1920px;
  margin: auto;
}


@font-face {
  font-family: miligram;
  src: url(../public/fonts/MilligramText-Regular.otf);
  font-weight: normal;
}

@font-face {
  font-family: miligram;
  src: url(../public/fonts/MilligramText-Medium.otf);
  font-weight: 500;
}
@font-face {
  font-family: miligram;
  src: url(../public/fonts/MilligramText-Bold.otf);
  font-weight: bold;
}
@font-face {
  font-family: miligram;
  src: url(../public/fonts/Milligram-Extrabold.otf);
  font-weight: 900;
}
@font-face {
  font-family: miligram-heavy;
  src: url(../public/fonts/Milligram-Heavy.otf);
  font-weight: normal;
}
@font-face {
  font-family: miligram-light;
  src: url(../public/fonts/Milligram-Light.otf);
  font-weight: normal;
}