.switchCnt{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 15px;
}

.barCnt{
    position: relative;
    margin: 0 15px;
    height: 40px;
    width: 90px !important;
    border-radius: 70px;
    background-color: white;
    display: flex;
    align-items: center;
}

.darkBarCnt{
    border: 1px solid #FAB40A;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}
.lightBarCnt{
    border: 1px solid #FAB40A;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset
}

.switchBox{
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    transition: all 500ms ease-in-out;
    cursor: pointer;
}

.btn{
    position: absolute;
     height: 100%;
     width: 100%;
     border-radius: 50%;
     background-image: linear-gradient(180deg, rgb(255, 227, 162) 2%, rgba(250, 180, 10, 0.50) 20%, #FAB40A 100%);
     box-sizing: border-box;
     border: 2px solid white;
     z-index: 2;
}

.btnBk{
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.70) 1.56%, rgba(197, 197, 197, 0.70) 100%);
    z-index: 1;
    left: -7%;
    top: 7%;
}

.txtDarkActive{
    color: #FAB40A;
}
.txtDark{
    color: white;
}

.txtLightActive{
    color: #FAB40A;
}
.txtLight{
    color: #7B7B7B;
}

@media screen and (min-width:1400px){
    .barCnt{
        height: 54px;
        width: 130px !important;
        box-sizing: border-box;
    }
    .switchBox{
        height: 64px;
        width: 64px;
    }
    .txt{
        font-size: 1.2rem;
    }
    .txt2{
        font-size: 1.2vw;
    }
}


@media screen and (min-width:1920px){
    .txt2{
        font-size: 23.04px;
    }
}