/********************* Header Style ************************/
#header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 15px 0;
  background-color: black;
  color: white;
  z-index: 10;
  max-width: 1920px;
}
.hUpper {
  width: auto;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
}
.hLeft {
  display: flex;
  align-items: center;
}
.hLogo {
  height: 58px;
  width: 58px;
  border-radius: 50%;
  background-color: black;
  overflow: hidden;
}
.hLogo img {
  height: auto;
  width: 100%;
}
.hlTxtCnt {
  display: flex;
  align-items: flex-end;
  margin: 0 5px 0 15px;
}
.logoText,
.logoText a {
  font-weight: 900;
  font-size: 30px;
  text-decoration: none;
  color: white;
}
.logoText sup {
  font-size: 6px;
  font-weight: normal;
  position: relative;
  top: -6px;
}
.hAction {
  font-size: 10px;
  margin: 0 0 6px 5px;
  color: #fab40a;
}

.hRight {
  display: flex;
  align-items: center;
}
.pcLinks {
  display: none;
}
.pcLinks p,
.pcLinks a {
  color: white;
  font-size: 14px;
  margin: 0 35px 0 0;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}
.pcLinks a:hover {
  color: #fab40a;
}
.pcLinks a:active {
  color: #bf8d1a;
}

.btn {
  background-color: #fab40a;
  border-radius: 50px;
  border: none;
  padding: 12px 40px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
}
.btn:hover {
  background-color: #ffca48;
}
.btn:active {
  background-image: linear-gradient(to right, #ffe8b2, #f6d074, #fab40a 65%);
}
.btnShadow {
  background-color: #fab40a;
  border-radius: 50px;
  border: none;
  padding: 12px 40px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  box-shadow: 0 5px 0 rgb(197, 197, 197);
}
.shadowBtn {
  box-shadow: 0 5px 3px rgb(197, 197, 197);
}
.htBtn {
  border: 1.5px solid rgb(251, 251, 170);
  font-size: 18px;
}
.h-btn {
  display: none;
  background-color: #fab40a;
  border-radius: 50px;
  border: none;
  padding: 12px 55px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid #f6d074;
}
.h-btn:hover {
  background-color: #ffca48;
}
.h-btn:active {
  background-image: linear-gradient(to right, #ffe8b2, #f6d074, #fab40a 65%);
}
.menuBtn {
  margin: 0 0 0 10px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.menuBtnLines {
  margin: 3px auto;
  background-color: #fab40a;
  height: 3px;
  width: 15px;
  border-radius: 10px;
}

.app-buttons {
  margin-top: 30px;
}

.app-buttons img {
  height: 50px;
  width: 150px;
  background-color: #000;
  object-fit: contain;
  object-position: center;
  border: 2px solid #a6a6a6;
  border-radius: 15px;
  margin: 0 10px;
}

.message-modal .ant-modal-content {
  background-color: rgb(25 25 26 / 95%);
  border: 2px solid #000;
  border-radius: 100px;
  padding: 20px 40px;
  text-align: center;
  box-shadow: 0px 0px 20px 4px #fab40a;
}

.message-modal .ant-modal-content h2 {
  color: #fab40a !important;
  font-size: 35px;
  letter-spacing: 1px;
  margin: 0;
  line-height: 1;
  padding-bottom: 10px;
}
.message-modal .ant-modal-content p {
  color: #fab40a !important;
  font-size: 25px;
  margin: 0;
  line-height: 1;
  font-weight: 500;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

footer .app-buttons {
  margin-top: 0;
  display: flex;
  flex-direction: column;
}

footer .app-buttons img {
  width: 150px;
  max-width: unset;
  margin: 0;
}

footer .app-buttons a:last-child img {
  margin-top: 10px;
}

@media screen and (min-width: 1000px) {
  .hLogo {
    height: 75px;
    width: 75px;
  }
  .logoText,
  .logoText a {
    font-size: 40px;
  }
  .hAction {
    font-size: 14px;
    margin: 0 0 7px 10px;
    color: #fab40a;
  }
  .pcLinks {
    display: flex;
  }
  .menuBtn {
    display: none !important;
  }
  .hlTxtCnt {
    flex-direction: row;
    align-items: flex-end;
  }
  .h-btn {
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .hUpper {
    margin: 0 100px;
  }
}

@media screen and (min-width: 1920px) {
  #header {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
/********************* Header Style End ************************/

/************************** PC FOOTER SECTION START ****************************************/
#footer {
  padding: 70px 0 70px 0;
  background-color: black;
  color: white;
  width: 100%;
  display: none;
}
#footer p,
#footer p > a {
  font-size: 14px;
  margin: 16px 0;
  font-weight: normal;
  color: gainsboro;
  text-decoration: none;
}
#footer h3 {
  color: #fab40a;
  font-size: 16px;
  font-weight: 500;
}
#footer a {
  color: gainsboro;
}
#footer > div:nth-child(1) {
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 80%;
  max-width: 400px;
  height: auto;
  row-gap: 40px;
}

.fboxmenu a,
.fboxcontact a {
  color: white;
  text-decoration: none;
  font-size: 14px;
}
.fboxconnect a {
  font-size: 14px;
}

.fbox:nth-child(1) {
  grid-column-end: 3;
  grid-column-start: 1;
}

.fbox > img {
  width: 350px;
}

.footerIbox {
  display: flex;
  width: 130px;
  justify-content: space-between;
  margin: 10px 0;
}

.footerIbox img {
  margin: 17px 0 !important;
  width: 17px !important;
  height: 17px !important;
}
.pcFlogoHolder {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.pcftop {
  display: flex;
  align-items: center;
}
.pcftop > div img {
  width: 8vw;
  height: 8vw;
  margin: 0 !important;
  padding: 0 !important;
  max-width: 100px;
  max-height: 100px;
}
.pcftop > div p {
  margin: 0 !important;
  padding: 0 !important;
}
.pcftRight {
  margin: 0 0 0 5%;
}
.pcftRight .mfLogoName {
  font-family: miligram-heavy;
  font-size: 6vw !important;
  color: white !important;
}
.pcftRight .mfLogoName sup {
  font-size: 8px;
  font-weight: bold;
  top: -20px !important;
}
.pcftRight .mfLogoSubName {
  font-weight: bold !important;
  color: #fab40a !important;
  font-size: 1.7vw !important;
  margin: 0 !important;
  font-weight: 500 !important;
}
.pcfbot {
  margin: 0 0 0 0 !important;
}
.pcfbot p {
  color: white !important;
  line-height: 22px;
}

@media screen and (min-width: 1000px) {
  #footer {
    display: block;
  }
  #footer > div:nth-child(1) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    width: 95%;
    max-width: unset;
    justify-items: center;
  }
  .fbox {
    margin: 0 5px;
  }
  .fbox:nth-child(1) {
    grid-column-end: 3;
    grid-column-start: 1;
    margin: 0 5px 0 0;
  }
  .fbox img {
    width: 25vw;
    max-width: 350px;
  }
  .fbox:nth-child(1) img {
    margin: 0 0 20px 0;
  }
}

@media screen and (min-width: 1150px) {
  .pcftRight .mfLogoName {
    font-size: 69px !important;
  }
  .pcftRight .mfLogoSubName {
    font-size: 19px !important;
  }
}

@media screen and (min-width: 1300px) {
  #footer > div:nth-child(1) {
    grid-template-columns: repeat(6, 1fr);
    width: 85%;
    justify-items: center;
  }
  .fbox {
    margin: 0 5px;
  }
  .fbox:nth-child(1) {
    grid-column-end: 3;
    grid-column-start: 1;
    margin: 0 5px;
  }
}
/************************** PC FOOTER SECTION END ****************************************/

/************************** MOB FOOTER SECTION START ****************************************/
#mobFooter {
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}
.mbfExtra {
  display: flex;
  justify-content: center;
  padding: 0 15px 30px 15px;
  font-size: 16px;
}
.mbfExtra * {
  font-size: 16px;
  color: gainsboro !important;
  margin: 0 5px;
}
#mobFooter > div > div {
  margin: 50px 0;
}
.mfTop {
  display: flex;
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  line-height: 150%;
}
.mfTop p {
  margin: 0 10px;
  font-weight: normal;
}
.mfTop a {
  color: white;
  text-decoration: none;
}
.mfContact > p,
.mfContact a {
  text-align: center;
  color: white;
  font-weight: normal;
  font-size: 15px;
}
.mfcHeading {
  color: #fab40a !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 150%; /* 19.5px */
  letter-spacing: 1.3px;
  text-transform: uppercase;
  margin: 0 0 20px 0;
}
.mfcAddress {
  line-height: 20px;
  margin: 0 0 20px 0;
}
.mfcPhone {
  margin: 0 0 20px 0;
}
.mfcPhone a {
  text-decoration: none;
}
.mfLogoHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mfLogo {
  width: 80px;
  margin: 0;
}
.mfLogoName {
  color: white;
  font-size: 40px;
  font-weight: 900;
}
.mfLogoName > sup {
  font-size: 6px;
  font-weight: normal;
  position: relative;
  top: -9px;
  margin: 0 0 0 2px;
}
.mfLogoSubName {
  color: #fab40a;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin: 3px 0 0 0;
}
.mfCopyRight {
  color: white;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.mfLogoCnt {
  display: flex;
  justify-content: center;
}
.mfLogoCnt img {
  height: 15px;
  width: 15px;
  margin: 0 7px;
}

.mydropdown {
  height: 100%;
  width: 100%;
  color: #c4c4c4;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.activeMob,
.activePc {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  z-index: 1;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 15px 0;
  transform: translate(-1px, 0px);
}
.activeMob .myddLabel {
  padding: 0 8px;
  margin: 2px 15px 10px 15px;
}
.activeMob .dropdownBtn {
  width: stretch;
  padding: 7px 18px;
}
.activePc {
  border: 2px solid #fab40a;
  border-radius: 30px;
  transform: translate(-2px, -2px);
}
.activePc .myddLabel {
  padding: 0 8px;
  margin: 2px 15px 10px 15px;
}
.activePc .dropdownBtn {
  padding: 7px 18px;
  width: stretch;
}
.activePc .dropdownBtn:hover {
  background: #fab40a;
  color: white;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
}
.myddLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
  cursor: pointer;
  margin: 0 15px;
}
.myddLabelVal {
  color: black;
}
.myddLabelPlaceH {
  color: rgb(161, 161, 161);
}
.dropdownBtn {
  border: none;
  display: block;
  height: 100%;
  width: 100%;
  background-color: transparent;
  text-align: left;
  margin: 2px 15px;
  padding: 7px 10px;
  cursor: pointer;
}
.dropdownBtn:hover {
  background-color: #e5e5e5;
}

.dropBk {
  border-radius: 50%;
  background: #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23px;
  width: 23px;
  transition: all 500ms ease-in-out;
}

@media screen and (min-width: 1000px) {
  .dropBk {
    background-color: #fab40a;
  }
  #mobFooter {
    display: none;
  }
  .mbfExtra {
    padding: 30px 15px 10px 15px;
    justify-content: flex-end;
  }

  .mbfExtra * {
    font-size: 14px;
  }
}

/************************** MOB FOOTER SECTION END ****************************************/

/******************************  MOB MENU *****************************************/

.mobMenu {
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
}
.mmLeft {
  width: 65%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: slideRight 500ms ease-in-out;
}
.mmLeft ul {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  list-style-type: none;
  color: white;
}
.mmLeft ul li {
  margin: 10px 0;
  transition: all 500ms ease-in-out;
  cursor: pointer;
  font-size: 20px;
}

.mmLeft ul li:hover {
  color: #fab40a;
  transform: scale(1.1);
}

.mmRight {
  flex: 1;
}
.fboxmenu > p {
  cursor: pointer;
}
@keyframes slideRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

/* MEDIA QUERY */

/* 
  ##Device = HD Desktops
  ##Screen = 1600px to higher resolution desktops
*/

@media screen and (min-width: 1600px) {
} /* Dont delete this bracket! */

/* 
    ##Device = Ipads, Ipad Pro 
    ##Screen = B/w 1024px to 1365px
  */
@media (min-width: 1024px) and (max-width: 1365px) {
} /* 1024px to 1365px Dont delete this bracket! */

/* 
    ##Device = Tablets, Ipads 
    ##Screen = B/w 768px to 1023px
  */
@media (min-width: 768px) and (max-width: 1023px) {
} /* 768px to 1023px  Dont delete this bracket! */

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
@media (min-width: 481px) and (max-width: 767px) {
} /* 481px to 767px (Landscape) Dont delete this bracket! */

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 280px to 479px
  */
@media (min-width: 280px) and (max-width: 480px) {
  .app-buttons {
    margin-top: 10px;
  }
  .app-buttons {
    display: flex;
    gap: 10px;
  }

  .app-buttons a img {
    margin: 0;
  }

  .message-modal .ant-modal-content {
    padding: 10px 20px;
  }

  .message-modal .ant-modal-content h2 {
    font-size: 18px;
    letter-spacing: 0;
  }
  .message-modal .ant-modal-content p {
    font-size: 14px;
  }
} /* 280px to 480px (Portrait) Dont delete this bracket! */
