#main > ol {
  padding: 0;
  /* color: rgb(109, 109, 109); */
  color: rgb(0 0 0);
  padding-left: 65px;
}

#main > ol ul {
  padding-left: 18px;
  list-style: disc;
}

#main > ol a {
  /* color: rgb(109, 109, 109); */
  color: #000;
  transition: all 0.4s ease-in-out;
}
#main > ol a:hover {
  color: #fab40a;
}

#main > ol > li {
  margin: 0 0 25px 0;
  line-height: 1.3;
}

#main > ol > li > ul > li {
  margin: 10px 0 10px 0;
}

.privacy-policy h1 {
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
  color: rgb(109, 109, 109);
  font-size: 28px;
  font-weight: bold;
}

.privacy-policy h2 {
  margin: 0;
  line-height: 1;
  padding-bottom: 25px;
  color: #000;
  font-size: 22px;
  font-weight: bold;
}

.privacy-policy > p {
  /* color: rgb(109, 109, 109); */
  color: #000;
  font-size: 18px;
  margin-bottom: 25px;
  padding-left: 35px;
  line-height: 1.3;
}

/* ============================================================================================================
                                           /* MEDIA QUERY STARTS */
/* =============================================================================================================*/

/* 
  ##Device = HD Desktops
  ##Screen = 1600px to higher resolution desktops
*/

@media screen and (min-width: 1600px) {
} /* Dont delete this bracket! */

/* 
    ##Device = Ipads, Ipad Pro 
    ##Screen = B/w 1024px to 1365px
  */
@media (min-width: 1024px) and (max-width: 1365px) {
} /* 1024px to 1365px Dont delete this bracket! */

/* 
    ##Device = Tablets, Ipads 
    ##Screen = B/w 768px to 1023px
  */
@media (min-width: 768px) and (max-width: 1023px) {
} /* 768px to 1023px  Dont delete this bracket! */

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
@media (min-width: 481px) and (max-width: 767px) {
  .privacy-policy h1 {
    font-size: 24px;
    font-size: 18px;
  }

  #main > ol {
    padding-left: 25px;
  }

  #main > ol > li,
  #main > ol > li b,
  #main > ol > li > ul > li,
  .privacy-policy > p {
    line-height: 1.5;
    font-size: 14px;
  }

  .privacy-policy > p {
    padding-left: 25px;
  }
} /* 481px to 767px (Landscape) Dont delete this bracket! */

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 360px to 479px
  */
@media (min-width: 360px) and (max-width: 480px) {
  .privacy-policy h1 {
    font-size: 24px;
    font-size: 18px;
  }

  #main > ol {
    padding-left: 25px;
  }

  #main > ol > li,
  #main > ol > li b,
  #main > ol > li > ul > li,
  .privacy-policy > p {
    line-height: 1.5;
    font-size: 14px;
  }

  .privacy-policy > p {
    padding-left: 25px;
  }
} /* 360px to 480px (Portrait) Dont delete this bracket! */
