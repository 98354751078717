.y-heading {
  color: gray;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin: 0 0 8px 0;
}
.b-heading {
  font-size: 2em;
  font-weight: bold;
  margin: 0 0 20px 0;
  text-align: center;
  letter-spacing: -2px;
}
.g-subHeading {
  font-size: 1em;
  font-weight: normal;
  text-align: center;
  color: #7b7b7b;
  width: 100%;
  margin: auto;
  max-width: 90%;
}
.g-subHeading > span {
  color: #fab40a;
  font-size: 1em;
  font-weight: 500;
}

.w-logo,
.y-logo {
  transition: transform 300ms cubic-bezier(0.67, 0.53, 0.76, 1.7),
    fill 500ms ease;
}

.w-logo:hover {
  fill: #fab40a;
  transform: scale(1.2);
}
.y-logo:hover {
  fill: #bf8d1a;
  transform: scale(1.2);
}
.clickTxt {
  cursor: pointer;
  text-decoration: underline;
  color: gray;
}
.clickTxt:hover {
  color: #fab40a;
}

.clickTxt:active {
  color: #bf8d1a;
}

@media screen and (min-width: 1000px) {
  .y-heading {
    font-size: 1.6vw;
    margin: 0 0 1% 0;
  }
  .b-heading {
    font-size: 3.6vw;
    margin: 0 0 1% 0;
  }
  .g-subHeading {
    font-size: 1.7vw;
    width: auto;
    max-width: 75%;
    line-height: 160%;
    font-weight: normal;
  }
  .g-subHeading > span {
    font-size: 1.7vw;
  }
}

@media screen and (min-width: 1700px) {
  .y-heading {
    font-size: 1.3vw;
  }
  .b-heading {
    font-size: 3.3vw;
  }
  .g-subHeading {
    font-size: 1.5vw;
  }
  .g-subHeading > span {
    font-size: 1.5vw;
  }
}

@media screen and (min-width: 1920px) {
  .y-heading {
    font-size: 24px;
  }
  .b-heading {
    font-size: 59px;
  }
  .g-subHeading {
    font-size: 25px;
  }
  .g-subHeading > span {
    font-size: 25px;
  }
}

/********************** Top section start ******************************************/

.homeTop {
  height: 35vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 80px;
}

.videoWrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}
.videoWrapper > video {
  height: 100vw;
}
.topOverlay {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 100px 0 70px 0;
  display: none;
}
.hBot {
  display: none;
  justify-content: flex-end;
  margin: 25px 20px 0 0;
}
.hBot img,
.hBot svg {
  width: 17px;
  height: 17px;
  margin: 0 0 0 18px;
  fill: white;
}
.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 50px; */
  margin-top: 30px;
  width: 100%;
}
.textWrapper > p {
  color: white;
  text-align: center;
}
.textWrapper > p > span {
  color: #fab40a;
}

.htTopPc {
  display: block;
  font-size: 19px;
  font-weight: bold;
  margin: 10px 25px;
}
.htCenter {
  color: #fab40a !important;
  font-weight: 900;
  font-size: 7.5vw;
}
.htBot {
  font-size: 1.6vw;
  width: 70%;
  margin: 30px 0 0 0;
  line-height: 130%;
}

.htBtn {
  margin: 40px 0 10px 0;
  font-weight: bold;
}

@media screen and (min-width: 1000px) {
  .homeTop {
    height: 100vh;
    max-height: 1100px;
    margin-top: 0;
  }
  .videoWrapper > video {
    height: 100%;
    width: auto;
  }
  .topOverlay {
    display: block;
    height: 100%;
  }
  .hBot {
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  .hBot {
    margin: 25px 100px 0 0;
  }
}

@media screen and (min-width: 1320px) {
  .videoWrapper > video {
    height: auto;
    width: 100%;
  }
  .htBtn {
    height: 58px;
    font-size: 22px;
    width: 300px;
  }
}

@media screen and (min-width: 1920px) {
  .htCenter {
    font-size: 134px;
    line-height: 1.2;
  }
  .htBot {
    font-size: 30px;
  }
}

/*********************************** Top section Ends ***************/

/************** Mobile version Top Overlay start ****************/

.mobOverLay {
  width: 100%;
  height: auto;
  background: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.mobOverLay > div {
  margin: 50px 25px;
}
.mobTopPc {
  font-size: 14px;
  margin: 0 0 10px 0;
}
.mobCenter {
  color: #fab40a;
  font-size: 24px;
  font-weight: 900;
  margin: 0 0 20px 0;
}
.mobBot {
  font-size: 1em;
  line-height: 20px;
}

@media screen and (min-width: 1000px) {
  .mobOverLay {
    display: none;
  }
}

/************** Mobile version Top Overlay end ****************/

/************** Be among the first section start ****************/

.btfCnt {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
}
.btfCnt > div {
  margin: 35px 15px;
  width: 100%;
}
.btnMobMsg {
  color: gainsboro;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  margin: 0 0 7px 0;
}
.btfHeading {
  color: #fab40a;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  margin: 0 20px 20px 20px;
}
.btfSubHeading {
  font-size: 1em;
  color: gainsboro;
  text-align: center;
  margin: 0 30px 40px 30px;
}
.btfBtnCnt {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 35px;
}
.btfBtnCnt > div {
  width: 100%;
  margin: auto;
  max-width: 500px;
}
.superInpCnt {
  height: auto;
  width: 100%;
}
.superInpCnt label {
  color: white;
}
.inpCnt {
  height: 50px;
  padding: 4px 8px;
  border-radius: 5px;
  border: 1px solid gray;
  background-color: white;
  margin: 15px 0;
  display: flex;
  align-items: center;
  width: auto;
  color: black;
  position: relative;
}
.inpCntInner {
  flex: 1;
  display: flex;
}
.myselect {
  flex: 1;
  height: auto;
  padding: 10px 0 !important;
  margin: 0 15px;
  font-size: 18px;
  font-weight: normal;
}
.ant-select-selection-item {
  font-weight: normal !important;
  font-size: 17px !important;
}
.ant-select-item-option-content {
  font-weight: normal !important;
  font-size: 17px !important;
}
.myselect::placeholder {
  color: transparent;
}
.pcBtn {
  display: none;
}
.mobBtn {
  margin: 20px 0 5px 0;
  width: 100%;
  max-width: 500px;
}

.btfFirst {
  background-color: black;
}
.btfFirst .b-heading {
  color: #fab40a;
  margin: 0 auto 20px auto !important;
  max-width: 300px;
}
.btfFirst .g-subHeading {
  color: white;
  margin: 0 auto 40px auto !important;
  max-width: unset;
}

.btfSec {
  background-color: black;
}
.btfSec .btnMobMsg {
  color: #7b7b7b;
}

.btfSec .b-heading {
  color: white;
  margin: 0 0 20px 0;
}
.btfSec .g-subHeading {
  color: white;
  max-width: unset;
  margin: 0 0 40px 0;
}
.btfSec .mobBtn {
  display: none;
}

@media screen and (min-width: 1000px) {
  .btfCnt > div {
    margin: 35px auto;
    max-width: unset!;
    position: relative;
  }
  .btfBtnCnt {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100% !important;
    max-width: unset;
    max-width: 1600px;
    margin: auto;
  }
  .btfBtnCnt > div {
    width: auto;
    flex: 1;
    max-width: unset;
    margin: 0 15px;
  }
  .btfBtnCnt > div:nth-child(3) {
    flex: 1.2;
  }
  .inpCnt {
    margin: 0 7px;
    width: auto;
    border-radius: 50px;
    border: 2px solid #fab40a;
  }
  .pcBtn {
    display: block;
    padding: 12px 15px !important;
    font-size: 16px !important;
  }
  .mobBtn {
    display: none;
  }
  .superInpCnt label {
    display: none;
  }
  .myselect::placeholder {
    color: rgb(161, 161, 161);
  }
  .btnMobMsg {
    display: none;
  }

  .btfFirst .b-heading {
    margin: 0 0 1% 0 !important;
    max-width: unset !important;
  }
  .btfFirst .g-subHeading {
    margin: 0 auto 2% auto;
  }
  .btfSec .b-heading {
    margin: 0 0 2% 0;
  }
  .btfSec .g-subHeading {
    margin: 0 auto 4% auto;
  }
}
@media screen and (min-width: 1200px) {
  .pcBtn {
    padding: 12px 25px !important;
    font-size: 18px !important;
  }
}
@media screen and (min-width: 1400px) {
  .pcBtn {
    padding: 12px 40px !important;
    font-size: 22px !important;
  }
  .myselect {
    font-size: 1.2rem;
  }
  .formInp {
    font-size: 1.2rem !important;
  }

  .btfBtnCnt > div:nth-child(3) {
    flex: 1.5;
  }
  .btnInp {
    padding: 10px 70px !important;
    font-weight: 500 !important;
    font-size: 1.5rem !important;
  }
}
/************** Be among the first section end ****************/

/************************** ABOUT SECTION start *******************/

.aboutSection {
  width: auto;
  height: auto;
}
.aboutSection > div {
  width: auto;
  height: auto;
  margin: 50px 0;
  overflow: hidden;
}

.aboutSection > div > div:nth-child(1),
.aboutSection > div > div:nth-child(3) {
  margin: 0 30px;
}

.twoImagePc {
  position: relative;
  margin: auto;
  width: auto;
  height: 100vw;
  max-height: 600px;
}
.twoImagePc > img {
  position: absolute;
  height: 100%;
  width: auto;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.twoImagePc > img:nth-child(1) {
  animation: fade1 9s infinite ease-in-out;
}
.twoImagePc > img:nth-child(2) {
  animation: fade2 9s infinite ease-in-out;
}

@keyframes fade1 {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade2 {
  0% {
    opacity: 1;
  }
  5% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.aboutContents {
  margin: 30px 30px 0 30px !important;
}

.atcBtnCnt {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.atcBtnCnt > div {
  margin: 10px 0;
}
.atcBtnCnt > div img {
  margin: 0 10px;
}

.atcBtnCnt .btn,
.atcBtnCnt .htBtn {
  margin: 0;
}

.tileBtnCnt {
  margin: 30px 0 0 0;
  text-decoration: none;
}
.tileBtnCnt a {
  text-decoration: none;
}

.tileMobBtn {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  font-weight: bold;
  color: #fab40a;
  justify-content: space-around;
  cursor: pointer;
}

.aboutPcContents {
  display: none;
}

@media screen and (min-width: 1000px) {
  .aboutSection {
    margin: 50px 0;
  }
  .aboutSection > div {
    width: 85%;
    display: grid;
    grid-template-columns: repeat(100, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
    margin: 0 auto 150px auto;
    height: auto;
    overflow: visible;
  }

  .aboutPcContents {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column-start: 41;
    grid-column-end: 101;
  }
  .aboutPcContents > div {
    margin-top: 23.3px;
  }
  .aboutPcContents .atcBtnCnt {
    margin: 32px 0 0 0;
  }

  .aboutHeaders {
    display: none;
  }
  .aboutContents {
    display: none;
  }

  .aboutImage {
    grid-column-start: 1;
    grid-column-end: 31;
    display: flex;
    justify-items: center;
  }
  .twoImagePc {
    position: relative;
    display: block;
    margin: auto;
    width: 25vw;
    height: 45vw;
    max-height: unset;
  }
  .twoImagePc > img {
    position: absolute;
    width: auto;
    height: 100%;
    left: 0;
    top: 0;
    transform: scale(1.2);
  }
  .atcBtnCnt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 52px 0 0 0;
  }

  .atcBtnCnt > div img,
  .atcBtnCnt > div svg {
    margin: 0 1vw;
    width: 2vw;
    max-width: 27px;
    fill: #fab40a;
  }

  .aboutTxtCnt .y-heading {
    margin: 0 0 2% 0;
  }
  .aboutTxtCnt .b-heading {
    margin: 0 0 32px 0;
  }
  .tileMobBtn {
    display: none;
  }

  .tilePcBtn {
    display: block;
  }
}

@media screen and (min-width: 1500px) {
  .aboutSection > div {
    padding: 50px 0 0 0;
  }
  .twoImagePc {
    height: 43vw;
  }
}
@media screen and (min-width: 1700px) {
  .aboutSection > div {
    width: 75%;
  }
  .twoImagePc {
    height: 38vw;
  }
}
@media screen and (min-width: 1920px) {
  .twoImagePc {
    height: 729.6px;
  }
  .aboutHeaders {
    grid-row-start: 1;
    grid-row-end: 30;
  }

  .aboutContents {
    grid-row-start: 30;
    grid-row-end: 101;
  }
}
/********* ABOUT SECTION ENDS HERE ************/

/************************** IMAGE SLIDER SECTION START *******************/
.slider {
  width: 100%;
  height: 55vw;
  max-height: 960px;
  position: relative;
  overflow: hidden;
}

.imgWrapper,
.wrapperBk,
.wrapperFront {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.wrapperBk img {
  width: 100%;
  height: 100%;
}
.sliderBk button {
  display: none !important;
}
.sliderFront {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  z-index: 8;
}
.sfCnt {
  position: absolute;
  bottom: 10%;
  left: 5%;
}
.sfUp {
  display: flex;
  align-items: flex-end;
  margin: 0 0 0 0;
}
.sfUp p {
  color: white;
  font-size: 24px;
  margin: 0 0 0 7px;
  position: relative;
  top: -2px;
}
.sfUp > img {
  width: 160px;
}
.sfDown > p {
  font-family: miligram-heavy;
  font-size: 50px;
  color: white;
}

@media screen and (min-width: 1000px) {
  .sfUp {
    margin: 0 0 7px 0;
  }
  .sfUp > img {
    width: 21vw;
  }
  .sfUp > p {
    font-size: 3vw;
    position: relative;
    top: 0;
    transform: translateY(-8%);
  }
  .sfDown > p {
    font-size: 8vw;
    font-weight: 900;
    color: white;
  }
}

@media screen and (min-width: 1920px) {
  .sfUp > img {
    width: 403.2px;
  }
  .sfUp > p {
    font-size: 57.6px;
  }
  .sfDown > p {
    font-size: 153.6px;
  }
}
/************************** IMAGE SLIDER SECTION END *******************/

/***************************BOOK A SHOT SECTION START *****************************/

.bookShot {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bookShot > div {
  margin: 50px 35px;
}

.stepsCnt {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stepBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0 30px 0;
  width: 100%;
}
.stepBox img {
  width: 250px;
}
.stepTxtCnt {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin: 13px 0 0 0;
}
.stepTxtCnt > p {
  margin: 20px 0 0 0;
  color: #7b7b7b;
  text-align: center;
}
.yellowCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: black;
  background: #fab40a;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

@media screen and (min-width: 1000px) {
  .bookShot {
    border-bottom: 2px solid #e5e5e5;
  }
  .bookShot > div {
    margin: 50px 35px 65px 35px;
    width: auto;
  }
  .stepsCnt {
    flex-direction: row;
    justify-content: center !important;
    align-items: flex-start;
    width: 100% !important;
    max-width: 1100px !important;
    margin: auto;
  }
  .stepBox {
    margin: 0 1%;
  }
  .stepBox img {
    width: 18rem !important;
    height: 32rem !important;
  }
  .stepTxtCnt > p {
    margin: 15px 0 0 0;
    width: 77%;
  }
  .yellowCircle {
    width: 50px;
    height: 50px;
  }
}

@media screen and (min-width: 1600px) {
  .stepsCnt {
    max-width: 1350px !important;
  }
  .stepBox {
    margin: 0;
  }
  .stepBox img {
    width: 24rem !important;
    height: 46rem !important;
  }
}

@media screen and (min-width: 1920px) {
  .stepBox img {
    width: 480px;
  }
}

/***************************BOOK A SHOT SECTION END *****************************/

/**************************GRID SECTION START ***********************/
.gridCnt {
  padding: 70px 0 0 0;
}
.tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 20px 0;
}

.tileImgCnt {
  height: 60vw;
  max-height: 1152px;
  width: 100%;
}

.tile:nth-child(1) .tileImgCnt {
  background: url("../../public/images/forcustomers.png");
  background-size: cover;
  background-position: center;
}
.tile:nth-child(2) .tileImgCnt {
  background: url("../../public/images/forphotographers.png");
  background-size: cover;
  background-position: center;
}
.tile:nth-child(3) .tileImgCnt {
  background: url("../../public/images/forbrands.png");
  background-size: cover;
  background-position: center;
}

.tileContent > div {
  margin: 50px auto;
  width: 80%;
  height: auto;
}
.tileContent .y-heading {
  color: #fab40a !important;
}

.leftAlign {
  text-align: left !important;
  max-width: unset !important;
  padding: 0 !important;
  width: 100% !important;
}
.tileBtnCnt .btn,
.tileBtnCnt .htBtn {
  margin: 0;
}

@media screen and (min-width: 1000px) {
  .gridCnt {
    padding: 0 0 0 0;
  }
  .tile {
    flex-direction: row;
    margin: 0;
  }
  .tileImgCnt {
    flex: 1;
    height: 30vw;
    max-height: 600px !important;
  }
  .tileContent {
    flex: 1;
  }
  .tileContent > div {
    margin: 0 auto;
    width: 80%;
  }
  .tile:nth-child(2) .tileImgCnt {
    order: 2;
  }
  .tileContent .y-heading {
    margin: 0 0 1.5% 0 !important;
  }
  .tileContent .b-heading {
    margin: 0 0 3% 0 !important;
  }
  .tileContent .g-subHeading {
    line-height: 130%;
    font-size: 0.8rem !important;
  }
}

@media screen and (min-width: 1400px) {
  .tileContent .g-subHeading {
    line-height: 150% !important;
    font-size: 1.2rem !important;
  }
}

@media screen and (min-width: 1920px) {
  .tileContent .g-subHeading {
    font-size: 1.4rem !important;
  }
}

/*********************** GRID SECTION END ****************************/

/************************** CONTACT US SECTION START ****************************************/
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formLeft {
  padding: 35px 0 10px 0;
}

.flContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 50px 35px 0 35px;
  max-width: 500px;
}
.form .y-heading {
  color: #fab40a;
}
.form .b-heading {
  max-width: 300px !important;
}

.formRight {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 0 35px 0;
}
.formRight > div {
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
}

.formInpCnt {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  height: 50px;
  width: auto;
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
  padding: 4px 8px;
  position: relative;
}
.txtarea .formInpCnt {
  height: auto !important;
}
.formInp {
  font-size: 18px;
  font-weight: normal;
}
.formInp::placeholder {
  color: transparent;
}

.twoRow {
  display: flex;
  justify-content: space-between;
}
.twoRow > div {
  width: 49%;
}

.mainFormInpCnt label {
  color: gray;
  font-weight: normal;
  font-size: 16px;
  margin: 0 0 10px 0;
  display: block;
}

.checkboxCnt {
  display: flex;
  align-items: center;
  margin: 25px 0;
}
.checkboxCnt a {
  color: #7b7b7b;
}
.checkboxCnt > input {
  margin: 0 17px 0 0;
  height: 20px;
  width: 20px;
  border-radius: 5px;
}
.checkboxCnt > label {
  color: #7b7b7b;
  font-size: 16px;
}
.checkboxCnt > label > a {
  font-size: 16px;
  text-decoration: underline;
}
.formRight .btn,
.formRight .htBtn {
  width: 100%;
  margin: 15px 0 0 0;
}

.form .b-heading {
  margin: 0 0 20px 0 !important;
}
.form .g-subHeading {
  margin: 0 auto 40px auto !important;
}

.pcInput {
  display: none;
}
.mobInput {
  display: block;
}

@media screen and (min-width: 1000px) {
  .form {
    flex-direction: row;
    padding: 150px 0;
    align-items: flex-start !important;
    width: 85%;
    margin: auto;
  }
  .form .b-heading {
    font-size: 3vw;
    max-width: unset !important;
  }
  .formLeft,
  .formRight {
    flex: 1;
    display: flex;
    align-items: flex-start;
    padding: 0 !important;
  }
  .formLeft {
    padding: 0;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
  .formRight {
    justify-content: flex-end !important;
    align-items: flex-start !important;
  }
  .formRight > div {
    margin: 0;
    width: 95%;
    max-width: 600px;
  }
  .flContent {
    align-items: flex-start !important;
    width: 95% !important;
    max-width: 750px;
    margin: 0;
  }

  .flContent .g-subHeading {
    max-width: unset;
    text-align: left !important;
  }
  .formRight > div > .mainFormInpCnt:nth-child(1) label {
    display: none;
  }

  .formRight .btn,
  .formRight .htBtn {
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .form .y-heading {
    margin: 0 0 3% 0 !important;
  }

  .form .b-heading {
    margin: 0 0 4% 0 !important;
  }
  .checkboxCnt > label {
    font-size: 1rem;
  }
  .checkboxCnt > label > a {
    font-size: 1rem;
  }
  .pcInput {
    display: flex;
  }
  .mobInput {
    display: none;
  }
}

@media screen and (min-width: 1920px) {
  .form .b-heading {
    font-size: 57.6px;
  }
}

/*********************** CONTACCT US SECTION ENDS ***************************/

/************************** SOCIAL SECTION START ****************************************/
.social {
  background: black;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.social > div {
  margin: 50px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.social .y-heading {
  font-size: 16px;
  font-weight: 700;
  /* margin: 0 0 20px 0; */
  color: white;
}
.socialHeadingPc {
  color: #7b7b7b;
  font-size: 18px;
  display: none;
  margin: 0 0 20px 0;
  text-align: center;
  font-weight: 500;
}
.social .b-heading {
  font-size: 30px;
  font-weight: bold;
  margin: 0 auto 40px auto;
  color: #fab40a;
  letter-spacing: -2px;
  max-width: 330px;
}

.iconbox {
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.iconbox img,
.iconbox svg {
  margin: 0 15px;
  height: 30px;
  width: 30px;
  fill: white;
}

@media screen and (min-width: 1000px) {
  .social .b-heading {
    max-width: unset;
    margin: 0 0 60px 0;
  }

  .socialHeadingPc {
    display: block;
  }
  .social > div {
    margin: 120px auto;
  }
  .iconbox img,
  .iconbox svg {
    margin: 0 40px;
    height: 70px;
    width: 70px;
  }
}

@media screen and (min-width: 1500px) {
  .socialHeadingPc {
    margin: 0 0 1% 0;
    display: block;
    font-size: 1vw;
  }
  .social .b-heading {
    margin: 0 0 4% 0;
    font-size: 2vw;
  }
  .iconbox img,
  .iconbox svg {
    margin: 0 40px;
    height: 5vw;
    width: 5vw;
  }
}
@media screen and (min-width: 1920px) {
  .socialHeadingPc {
    font-size: 19.2px;
  }
  .social .b-heading {
    font-size: 38.4px;
  }
  .iconbox img,
  .iconbox svg {
    height: 96px;
    width: 96px;
  }
}
/********************* SOCIAL END HERE ******************************/
