.pTopSection {
  height: 100vh;
  max-height: 650px;
  width: 100%;
  background-color: #0b0a0a;
  overflow: hidden;
}
.ptBelow {
  position: relative;
  top: 0;
  left: 0;
  padding: 100px 0 70px 0;
}
.ptbContent {
  margin: 0 25px;
}
.ptAbove {
  position: relative;
  top: -110px;
  left: 0;
  height: auto;
  width: 100%;
}
.ptbHeading {
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin: 35px 0 25px 0;
}
.ptbSubHeading {
  color: #fab40a;
  text-align: center;
  font-weight: 900;
  font-size: 40px;
}

.ptAbove {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ptaContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ptacImage {
  width: 210px;
  margin: 0 0 40px 0;
}

@media screen and (min-width: 1000px) {
  .pTopSection {
    min-height: 700px;
    height: 60vw;
    max-height: unset;
  }
  .ptbContent {
    width: 80%;
    margin: 0 auto;
  }
  .ptbHeading {
    font-size: 1.5vw;
  }
  .ptbSubHeading {
    font-size: 8vw;
  }
  .ptAbove {
    top: -19vw;
  }
  .ptacImage {
    width: 22vw;
  }
}
@media screen and (min-width: 1920px) {
  .pTopSection {
    height: 1152px;
  }
  .ptbHeading {
    font-size: 28.8px;
  }
  .ptbSubHeading {
    font-size: 153.6px;
  }
  .ptAbove {
    top: -364.8px;
  }
  .ptacImage {
    width: 422.4px;
  }
}

/*******************************************************/

@media screen and (min-width: 1000px) {
  .photographer .btfBtnCnt {
    width: 80% !important;
    max-width: 1250px !important;
    margin: auto;
  }
  .photographer .btfBtnCnt > div {
    flex: 1.3;
    width: auto;
    margin: 0 !important;
  }
  .photographer .btfBtnCnt > div:nth-child(2) {
    flex: 2;
    width: auto;
  }
  .photographer .pcBtn {
    padding: 12px 15% !important;
  }
}
@media screen and (min-width: 1600px) {
  .photographer .pcBtn {
    padding: 10px 15% !important;
    font-size: 1.5rem !important;
  }
}

/************************************************************/

.makeAmove {
  background-color: gainsboro;
}
.mavTile {
  padding: 50px 0 1px 0;
  margin: 0 0 0 0;
  background-color: white;
}
.mavtHeading > div,
.mavtContent > div {
  margin: 0 35px;
}
.mavtContent {
  margin: 35px 0;
}
.mavtImage {
  width: 85%;
  max-width: 500px;
  height: 500px;
  overflow: hidden;
  position: relative;
  margin: auto;
  border-radius: 25px;
  overflow: hidden;
}
.mavtImage img {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 1000px) {
  .mavTile {
    display: grid;
    grid-template-columns: repeat(100, minmax(0, 1fr));
    grid-template-rows: repeat(100, minmax(0, 1fr));
    margin: 0;
    padding: 0;
    padding: 70px 0 0 0;
    height: 60vw;
    max-height: 1152px;
  }
  .mavtHeading > div,
  .mavtContent > div {
    margin: 0 !important;
    padding: 0 !important;
  }

  .mavTile:nth-child(1) .mavtImage {
    grid-column-start: 1;
    grid-column-end: 41;
    grid-row-start: 1;
    grid-row-end: 101;
    width: 75%;
    height: 80%;
    max-width: unset;
    margin: 0 auto;
  }
  .mavTile:nth-child(1) .mavtHeading {
    grid-column-start: 41;
    grid-column-end: 101;
    grid-row-start: 1;
    grid-row-end: 20;
    margin: 0 0 32px 0;
  }
  .mavTile:nth-child(1) .mavtContent {
    grid-column-start: 41;
    grid-column-end: 101;
    grid-row-start: 20;
    grid-row-end: 101;
    padding: 0;
    margin: 0;
  }

  .mavTile:nth-child(2) .mavtImage {
    grid-column-start: 61;
    grid-column-end: 101;
    grid-row-start: 1;
    grid-row-end: 101;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .mavTile:nth-child(2) .mavtImage > img {
    transform: translate(-50%, -7%);
    width: 110%;
  }
  .mavTile:nth-child(2) .mavtHeading {
    grid-column-start: 1;
    grid-column-end: 61;
    grid-row-start: 1;
    grid-row-end: 20;
    margin: 0 0 32px 0;
  }
  .mavTile:nth-child(2) .mavtContent {
    grid-column-start: 1;
    grid-column-end: 61;
    grid-row-start: 20;
    grid-row-end: 101;
    padding: 0;
    margin: 0;
  }

  .mavTile:nth-child(2) .mavtHeading > div,
  .mavTile:nth-child(2) .mavtContent > div {
    margin-left: 12.5% !important;
  }
  .mavtHeading > div,
  .mavtContent > div {
    width: 85%;
    margin: 0 auto;
  }
}

/*****************************************************************/

@media screen and (min-width: 1000px) {
  .joinUs {
    display: grid !important;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    column-gap: 0;
    row-gap: 15px;
    height: auto;
    width: 100%;
    padding: 30px 0;
    justify-content: space-between;
  }
  .joinUs .stepBox {
    margin: 0;
  }
  .joinUs .stepBox img {
    height: 31rem !important;
  }
}

@media screen and (min-width: 1600px) {
  .joinUs .stepBox img {
    height: 44rem !important;
  }
}

/*********************************************/

.pGrid .tile:nth-child(1) .tileImgCnt {
  background: url("../../public/images/pgrid1.jpeg");
  background-size: cover;
  background-position: center;
}
.pGrid .tile:nth-child(2) .tileImgCnt {
  background: url("../../public/images/pgrid2.jpeg");
  background-size: cover;
  background-position: center;
}
.pGrid .tile:nth-child(3) .tileImgCnt {
  background: url("../../public/images/pgrid3.jpeg");
  background-size: cover;
  background-position: center;
}

/***********************************************************/

.guidelines {
  width: 80%;
  margin: 0 auto !important;
}
.guidelines .mavTile {
  padding: 20px 0 0 0 !important;
}

.guidelines .mavtHeading > div,
.guidelines .mavtContent > div {
  margin: 0;
}
.guidelines .mavtContent {
  margin: 55px 0 0 0;
}
.guidelines .mavtImage {
  width: 100%;
  height: 110vw;
  max-width: 400px;
  max-height: 550px;
  border-radius: 0 !important;
  overflow: visible !important;
}
.guidelines .mavtImage > img {
  height: 100%;
  width: 100%;
  left: 50%;
  margin: 0;
  transform: translateX(-50%);
}

.shadowCircle {
  position: absolute;
  width: 90%;
  height: 50px;
  background-color: transparent;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
  box-shadow: 0 10px 10px #66727e, 0 20px 5px #dce7f3;
}

@media screen and (min-width: 1000px) {
  .guidelines {
    width: 90%;
    margin: auto !important;
  }
  .guidelines .mavTile {
    height: auto;
    padding: 70px 0 0 0 !important;
    height: 45vw;
    max-height: 864px;
    justify-items: center;
    width: 100%;
  }
  .guidelines .mavTile:nth-child(1) .mavtImage {
    grid-column-start: 61;
    grid-column-end: 101;
    grid-row-start: 1;
    grid-row-end: 101;
    height: 100%;
    width: 100%;
    max-height: unset;
    margin: 0 !important;
    padding: 0 !important;
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
  .guidelines .mavTile:nth-child(1) .mavtImage .mavtImageHolder {
    width: 33vw;
    max-width: 633.6px;
    position: relative;
  }
  .guidelines .mavTile:nth-child(1) .mavtImage img {
    width: 100%;
    height: 100%;
  }

  .guidelines .mavTile:nth-child(1) .mavtImage .mavtImageHolder::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 7%;
    background: rgba(255, 255, 255, 0.13);
    top: 0;
    left: 0;
    z-index: 1;
  }
  .guidelines .mavTile:nth-child(1) .mavtHeading {
    grid-column-start: 1;
    grid-column-end: 61;
    grid-row-start: 1;
    grid-row-end: 30;
    padding: 0 !important;
    margin: 0 !important;
  }
  .guidelines .mavTile:nth-child(1) .mavtContent {
    grid-column-start: 1;
    grid-column-end: 61;
    grid-row-start: 30;
    grid-row-end: 101;
    padding: 0 !important;
    margin: 0 !important;
  }
}

/* MEDIA QUERY */

/* 
  ##Device = HD Desktops
  ##Screen = 1600px to higher resolution desktops
*/

@media screen and (min-width: 1600px) {
} /* Dont delete this bracket! */

/* 
    ##Device = Ipads, Ipad Pro 
    ##Screen = B/w 1024px to 1365px
  */
@media (min-width: 1024px) and (max-width: 1365px) {
} /* 1024px to 1365px Dont delete this bracket! */

/* 
    ##Device = Tablets, Ipads 
    ##Screen = B/w 768px to 1023px
  */
@media (min-width: 768px) and (max-width: 1023px) {
} /* 768px to 1023px  Dont delete this bracket! */

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
@media (min-width: 481px) and (max-width: 767px) {
} /* 481px to 767px (Landscape) Dont delete this bracket! */

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 280px to 479px
  */
@media (min-width: 280px) and (max-width: 480px) {
  .pTopSection {
    height: auto;
    max-height: 710px;
  }

  .pTopSection .app-buttons {
    margin-top: 20px;
  }
} /* 280px to 480px (Portrait) Dont delete this bracket! */
